// ~~~ CONTENTFUL PAGE ~~~
/* eslint-disable */

import React from 'react'
import Helmet from 'react-helmet'
import Layout from 'components/Layout'
import Hero from 'components/Contentful/Hero'
import References from 'components/Contentful/References'

const Page = props => {
  return (
    <Layout location={props.location}>
      <Helmet
        title="Unser Wärmepumpen-Wissen"
        meta={[
          { name: 'description', content: 'Alles rund um die Themen Wärmepumpen, Heizungsaustausch und nachhaltiges Heizen finden Sie in unseren spannenden Artikeln. Schauen Sie jetzt rein!' },
          { name: 'keywords', content: 'Blog, Wissensdatenbank, Artikel' },
        ]}
      >
        
      </Helmet>
      
      <div
          className='bg__flex--white'
          key='7jxRTdtnrkeVylv1ZsKsGX-AKTDPFN8caECPKj1dj06J'
          id='wussten-sie-schon'
        >
          <Hero
            {...{
    "type": "hero",
    "id": "AKTDPFN8caECPKj1dj06J",
    "updatedAt": "2024-04-15T13:25:23.735Z",
    "title": "Wissenswertes über den Heizungsersatz, Stromproduktion und Fördergelder",
    "size": "Groß",
    "teaser": "Spannende Themen rund ums Heizen mit Wärmepumpen und der Kombination mit Photovoltaikanlagen. Übersicht zu den wichtigsten Fördergelder.",
    "image": {
        "description": "Bild Wärmepumpe",
        "contentType": "image/png",
        "url": "//images.ctfassets.net/wbm1kpreb3m8/6f6DgJJ8SuRSZbqhcXCFpa/4cad03b4d205c0f3096fc5f82da521aa/Referenzbild---Wissens-Seite.png"
    },
    "links": [
        {
            "type": "link",
            "id": "52LtUnYpq3pbMjNzaKpmjM",
            "updatedAt": "2019-05-03T15:34:26.732Z",
            "label": "Kontaktieren Sie uns",
            "url": "/kontakt"
        }
    ],
    "badge": {
        "type": "link",
        "id": "3P9prSaMlzdADoC8f1gPT7",
        "updatedAt": "2021-02-11T13:38:26.956Z",
        "label": "Fördergelder",
        "page": {
            "slug": "foerdergelder"
        }
    },
    "anchorName": "wussten-sie-schon"
}} location={props.location}
          />
        </div>
<div
          className='bg__flex--grey'
          key='7jxRTdtnrkeVylv1ZsKsGX-1HIplIsvJOeEj9gmFWGkHI'
          id='uebersicht-blogartikel'
        >
          <References
            {...{
    "type": "references",
    "id": "1HIplIsvJOeEj9gmFWGkHI",
    "updatedAt": "2024-04-22T11:20:55.331Z",
    "title": "Artikelübersicht",
    "description": "<p>Hier ist eine Übersicht von Artikeln aufgelistet, die unsere Kunden interessieren.</p>\n",
    "references": [
        {
            "type": "reference",
            "id": "3DeOtiPHKM8d5altvUvGMQ",
            "updatedAt": "2024-04-22T11:20:44.696Z",
            "title": "SIA 118 und OR: Wie bin ich abgesichert bei Baumängeln?",
            "quote": {
                "type": "quote",
                "id": "1WBy5SzWne2CKvpHjyP80u",
                "updatedAt": "2024-04-22T10:59:45.466Z",
                "quote": "Wenn Sie einen Vertrag mit jemandem eingehen, haben sowohl Sie als auch die anderen Parteien eine Obligation, die jeweilige Seite des Vertrags einzuhalten. In der Schweiz nennt man das Obligationenrecht (OR). Wenn es um jegliche Form von Bauarbeiten geht, zum Beispiel beim Einbau Ihrer Wärmepumpe, wird zusätzlich die SIA 118 Norm interessant - und die Herstellergarantie.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Damit Sie wissen, welche Rechte Sie im Schadensfall haben und wie Sie sich am besten vorbereiten, haben wir für Sie hier die wichtigsten Informationen zu SIA 118, Mängelrüge, Herstellergarantien (und ihre Limitationen) sowie die besten Vorgehensweisen bei Pfusch, verdeckten Baumängeln und sonstigen Problemen zusammengefasst.</p>\n",
            "date": "Mai 2024",
            "image": {
                "description": "Nachdenklicher Mann auf einer Baustelle mit Baumängeln",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4Vbj0ssbLPEiAWj2drvYrv/cb28dafb1685a34cb707f77119d5c2d7/Titelbild_Absicherung_Baum_ngel-01.jpg"
            },
            "link": {
                "type": "link",
                "id": "2FTpMR8RJ5tF0aPyk9RBcU",
                "updatedAt": "2024-04-22T11:20:40.125Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/sia-118-und-or-wie-bin-ich-abgesichert-bei-baumaengeln"
                }
            }
        },
        {
            "type": "reference",
            "id": "43RHbVB04SWuWLOsMXaQ7z",
            "updatedAt": "2024-04-22T08:40:36.461Z",
            "title": "Alte Wärmepumpe ersetzen - Investieren in die Zukunft",
            "quote": {
                "type": "quote",
                "id": "63aAZ6mLdY7iqIuauSWkHL",
                "updatedAt": "2024-04-22T08:18:27.869Z",
                "quote": "Die durchschnittliche Lebensdauer einer Wärmepumpe beträgt 15-25 Jahre. Wenn das Gerät in die Jahre kommt, ist es langfristig oft mit weniger Kosten verbunden, die alte Wärmepumpe mit einer neuen zu ersetzen, anstatt sie reparieren zu lassen.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Gesetzlich vorgeschrieben ist das Austauschen einer alten Wärmepumpe nicht. Anders als beim erstmaligen Einbau als Ersatz für eine Öl- oder Gasheizung gibt es allerdings auch keine Förderprogramme. Trotzdem kann das Ersetzen der Wärmepumpe Kosten sparen.</p>\n",
            "date": "April 2024",
            "image": {
                "description": "Eine Holzwande. Davor eine Luft/Wasser Wärmepumpe.",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2RH3pH4GkgoGOUoqevHcSJ/77a1a0d0aa819a8fc739d3870649deb4/Titelbild_WP_austauschen-01.jpg"
            },
            "link": {
                "type": "link",
                "id": "715KL7uSDV8g6lrZ9CjsLC",
                "updatedAt": "2024-04-22T08:40:32.648Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/alte-waermepumpe-ersetzen"
                }
            }
        },
        {
            "type": "reference",
            "id": "43gZgBHDQMkCzVJeMYBk2J",
            "updatedAt": "2024-04-16T11:32:34.922Z",
            "title": "Das Gebäudeprogramm für Fördergelder",
            "quote": {
                "type": "quote",
                "id": "3xowFaHWuofGrQlP1km5zi",
                "updatedAt": "2024-04-16T11:19:47.753Z",
                "quote": "Schweizweit werden rund 40% des Energieverbrauchs und ein Drittel des CO2-Ausstosses durch Gebäude verursacht. Mehr als eine Million Häuser sind energetisch sanierungsbedürftig. Ca. 56% der Gebäude in der Schweiz werden immer noch mit fossilen Energien beheizt. ",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Wir haben auf unserer Seite eine Übersicht über alle schweizweiten Förderprogramme aufgelistet. Als Heizungsmacher-Kund:in profitieren sie von unserem Komplett-Angebot, in dem auch die professionelle Beantragung von Förderleistungen inbegriffen ist.</p>\n",
            "date": "April 2024",
            "image": {
                "description": "Referenzbild Gebäudeprogramm",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/24hwwm3lhELJJGQ1pEfrWZ/612bd8f4ef98c7d40eaa49d6ef609ac2/01---Format---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "3uidE78Bsz7Gz7TxLLYut0",
                "updatedAt": "2023-03-09T08:47:41.754Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/das-gebaeudeprogramm-fuer-foerdergelder"
                }
            }
        },
        {
            "type": "reference",
            "id": "6dz2M44L3GI4ysp7mO4AV2",
            "updatedAt": "2024-03-01T16:55:21.559Z",
            "title": "CO2-Gesetz",
            "quote": {
                "type": "quote",
                "id": "7xr6lUXBCU073ZbDaBJisz",
                "updatedAt": "2021-05-12T16:22:30.468Z",
                "quote": "Das CO2-Gesetz hat das alleinige Ziel das Klima zu schützen und dem Klimawandel entgegen zu wirken.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Wie wird das CO2-Gesetz ab 2025 umgesetzt? Was bedeutet die Revision des CO2-Gesetzes für Hauseigentümer? Und welchen Einfluss hat das im Juni 2023 angenommene Klima- und Innovationsgesetz? Erfahren Sie es im Artikel.</p>\n",
            "date": "März 2024",
            "image": {
                "description": "Ein Wald mit einem See. Im See ist Co2 geschrieben.",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/b4LVD07WKTeD4ET1JEAF3/dc50ac3e0003462c654a068ef2a84a19/Titelbild_Co2_Gesetz-01.jpg"
            },
            "link": {
                "type": "link",
                "id": "6khhmekZ1ZT5vF6spT62pT",
                "updatedAt": "2023-03-09T08:47:23.934Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/co2-gesetz"
                }
            }
        },
        {
            "type": "reference",
            "id": "7m303JKjjVrc8JijwiSh91",
            "updatedAt": "2024-02-20T13:29:47.628Z",
            "title": "Kosten einer Solaranlage",
            "quote": {
                "type": "quote",
                "id": "3ExK8Ld0ekZwfEL0FPGbps",
                "updatedAt": "2024-02-20T12:33:17.774Z",
                "quote": "Wie viel Photovoltaik konkret kostet, hängt von vielen individuellen Faktoren ab. ",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Fest steht aber: Die Anschaffung und Installation sind heute, auch dank staatlicher Förderungen, so günstig wie noch nie. Wer ein Einfamilienhaus mit Photovoltaik ausstatten will, kann mit etwa 35’000 Franken an Ausgaben rechnen.</p>\n",
            "date": "Februar 2024",
            "image": {
                "description": "Photovoltaik Module mit Taschenrechner und Glühbirne",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5ndDAbChVlRkGJJXbxkQRL/7c4779d9b07f9ace6ece7a7b3e65b4e2/Titelbild_Kosten_Solaranlage-01.jpg"
            },
            "link": {
                "type": "link",
                "id": "yUtVNL9zVEUS4b8mvaTGP",
                "updatedAt": "2024-02-20T13:29:42.257Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/kosten-einer-solaranlage"
                }
            }
        },
        {
            "type": "reference",
            "id": "uKO6hFkpk5IabL7FVyEpF",
            "updatedAt": "2024-02-01T10:14:53.118Z",
            "title": "Ölheizung ersetzen: Informationen, Förderungen, Regelungen",
            "quote": {
                "type": "quote",
                "id": "7K3RSCA21thhhGf2NogfSY",
                "updatedAt": "2021-04-08T17:40:56.733Z",
                "quote": "Ölheizung ersetzen bedeutet: Mit erneuerbarer Energie Geld und CO2 einsparen und zusätzlich mehr Raum in den eigenen vier Wänden schaffen. ",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Der Ersatz von fossilen Heizsystemen durch solche mit erneuerbaren Energiequellen wird in der Schweiz aktiv gefördert. Bund, Kantone, Gemeinden sowie Energieversorger stellen Gelder von teilweise über 10'000 Franken zur Verfügung.\nWelche Vorschriften dabei zu beachten sind und welche Vorteile dabei entstehen, erfahren Sie in diesem Artikel.</p>\n",
            "date": "Januar 2024",
            "image": {
                "description": "Haus mit CO2-Schild Link zum Artikel",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2AHohTYnscewXNoLxGWIFG/dc328199c727a15f905246212e3d3bd6/Titelbild__lheizung_ersetzen-01.png"
            },
            "link": {
                "type": "link",
                "id": "4EfCEXSK3QQTCYTMCWKRNu",
                "updatedAt": "2023-03-09T08:47:18.021Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/oelheizung-ersetzen-informationen-foerderungen-regelungen"
                }
            }
        },
        {
            "type": "reference",
            "id": "7hDhvLTExl34Hld4QiN8nu",
            "updatedAt": "2023-11-16T14:02:56.312Z",
            "title": "Wärmepumpe und Heizkörper – Lohnt sich die Kombination?",
            "quote": {
                "type": "quote",
                "id": "1AhiyWVPadbk1Hzyk3UIXc",
                "updatedAt": "2023-11-16T12:42:47.562Z",
                "quote": "Während man beim Bau eines Hauses direkt eine moderne und effiziente Fussbodenheizung einbauen kann, finden sich im Altbau meist noch Heizkörper resp. Radiatoren. Aber keine Sorge: Eine Fussbodenheizung ist nicht die einzige Heizmöglichkeit für den Betrieb einer Wärmepumpe.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Auch Heizkörper können hervorragend mit einer Wärmepumpe funktionieren und ein effizientes und umweltfreundliches Heizen ermöglichen. Hier erfahren Sie, welche Radiatoren gut zu Wärmepumpen passen und was Sie sonst noch beachten sollten.</p>\n",
            "date": "November 2023",
            "image": {
                "description": "Bild eines Radiators resp. Heizkörper an einer Wand",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4icTF4pX4NFhwPrNdyQVlD/f81a86cc84dbf90329eb682892e6480c/Titelbild_Heizk_rper_W_rmepumpen-01.jpg"
            },
            "link": {
                "type": "link",
                "id": "CwgnabHAfFrH73ObOzqc0",
                "updatedAt": "2023-11-16T14:02:48.138Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpe-und-heizkoerper"
                }
            }
        },
        {
            "type": "reference",
            "id": "65vnJAKzzMu6QdfDviVAmS",
            "updatedAt": "2023-10-13T07:30:28.307Z",
            "title": "Pufferspeicher für die Wärmepumpe – ist das sinnvoll?",
            "quote": {
                "type": "quote",
                "id": "1ima0AzVjNyrvtcQAqiZK7",
                "updatedAt": "2023-10-13T06:01:50.343Z",
                "quote": "Wenn das Gebäude, die Heizleistung und eine intelligente Wärmepumpensteuerung es zulassen, kann in gewissen und wohl ausgesuchten Fällen auf einen Pufferspeicher verzichtet werden. Somit ist ein Pufferspeicher für den Betrieb einer Wärmepumpe nicht zwingend notwendig und erst einmal eine kleine Investition. Er trägt aber dazu bei, die von der Wärmepumpe bereitgestellte Wärme effizienter zu nutzen und damit das Heizsystem zu optimieren.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Ob eine Wärmepumpe einen Pufferspeicher braucht, wird in Fachkreisen kontrovers diskutiert und kann nicht abschliessend beurteilt werden. Erfahren Sie im Artikel wieso.</p>\n",
            "date": "Oktober 2023",
            "image": {
                "description": "Bild eines Viessmann Pufferspeichers",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/148aBu8qcEJ5ztf32JF0rV/fbcb814a58e8ee361f4bf406f65987d5/Bild_Pufferspeicher_f_r_die_W_rmepumpe_-_sinnvoll.jpg"
            },
            "link": {
                "type": "link",
                "id": "5O0FrgIekhyLXi8SS4anoX",
                "updatedAt": "2023-10-13T06:00:06.347Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/pufferspeicher-fuer-die-waermepumpe-ist-das-sinnvoll"
                }
            }
        },
        {
            "type": "reference",
            "id": "7EqRZtqUUmArObhZ5kWqEq",
            "updatedAt": "2023-10-13T08:43:31.715Z",
            "title": "Wärmepumpe: Kosten der verschiedenen Typen",
            "quote": {
                "type": "quote",
                "id": "4TTiK0C7ENrSIkI3LmuF46",
                "updatedAt": "2023-10-13T06:10:10.059Z",
                "quote": "Es wird zwischen 3 Wärmepumpen-Typen unterschieden, welche sich bei Ein- bis Zweifamilienhäusern aus gutem Grund durchgesetzt haben: Sie verfügen über das beste Preis-Leistungsverhältnis unter allen Heizvarianten und sind unterschiedlich kostenintensiv.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>In diesem Artikel erfahren Sie alles über die Anschaffungs- und Betriebskosten von verschiedenen Wärmepumpen Arten. Unsere Spezialisten erklären Ihnen, worauf es bei der Preisermittlung ankommt.</p>\n",
            "date": "September 2023",
            "image": {
                "description": "",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5DJ09pLp3DCqtKa7buL267/94d9b254efda65e2b17ca39762604129/Titelbild_Kosten_versch._W_rmepumpen-01.jpg"
            },
            "link": {
                "type": "link",
                "id": "4ghgrLBeaQacIIR5RV0QI2",
                "updatedAt": "2023-03-09T08:47:04.968Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/wie-hoch-sind-die-kosten-einer-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "6BJRn3C4DqSS7IySdbklFS",
            "updatedAt": "2023-08-30T11:56:49.014Z",
            "title": "Wie funktioniert eine Erdsondenbohrung?",
            "quote": {
                "type": "quote",
                "id": "3QL6t5K0cPt1hcWcyF72om",
                "updatedAt": "2021-02-10T14:46:36.586Z",
                "quote": "Eine Erdsondenbohrung ist beim Einbau einer Erdwärmepumpe – auch Erdsonden-Wärmepumpe oder Sole/Wasser-Wärmepumpe genannt, notwendig. Sie nutzt wie der Name es schon verrät die Erdwärme als Energiequelle.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Vorteil dieser Energiequelle: Die Temperatur der Erdwärme bleibt bei richtiger Auslegung das ganze Jahr hindurch konstant. Im Gegensatz zu Luft/Wasser-Wärmepumpen arbeitet die Erdwärmepumpe unter stabilen Betriebsbedingungen, was geringere Heiz- und Betriebskosten wie auch Langlebigkeit zur Folge hat.</p>\n",
            "date": "August 2023",
            "image": {
                "description": "Prinzip einer Wärmepumpe mit Erdsonde",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5u6oqdCEpdAr0GafhWaObX/d32aaaf57a2a2e69e78341a75598c711/Referen.png"
            },
            "link": {
                "type": "link",
                "id": "2wwkvKxObauUgxjdtMDebE",
                "updatedAt": "2023-03-09T08:46:58.794Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/wie-funktioniert-eine-erdsondenbohrung"
                }
            }
        },
        {
            "type": "reference",
            "id": "1OiTBDPYD4I4io1n2PtAud",
            "updatedAt": "2023-08-16T18:25:18.852Z",
            "title": "Restwertentschädigung Stadt Zürich",
            "quote": {
                "type": "quote",
                "id": "3EptwaGBaf7EKdTHPWQaFa",
                "updatedAt": "2023-08-16T18:24:41.627Z",
                "quote": "Die Restwertentschädigung ist eine zusätzliche Förderung, kann also ergänzend zu den üblichen Fördermöglichkeiten ausgezahlt werden. Sie setzt sich aus einem pauschalen und einem variablen Teil zusammen: Es gibt pauschal CHF 4000 für Heizungen, die maximal 25 Jahre alt sind. ",
                "author": "Lina Potreck"
            },
            "description": "<p>Eigenheimbesitzer mit alter Heizung aufgepasst: Um das Klimaziel «Netto-Null 2040» zu erreichen, zahlt die Stadt Zürich ein Fördergeld, wenn man seine Öl- und Gasheizung vorzeitig durch eine Wärmepumpe oder einen Anschluss an die Fernwärme ersetzt.</p>\n",
            "date": "August 2023",
            "image": {
                "description": "",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/60H7tRkXIvJvApYRAJIcxh/1340ee8dcad8a256da80603525b86927/2308---Restwertentsch__digung-Referenzbild.png"
            },
            "link": {
                "type": "link",
                "id": "7EAjG1uosokg4ALhjhIDPx",
                "updatedAt": "2023-08-16T18:25:15.760Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/stadt-zuerich-restwertentschaedigung-bei-vorzeitigem-heizungsersatz"
                }
            }
        },
        {
            "type": "reference",
            "id": "7bAOz1jUUMsIKCi8nMCBLx",
            "updatedAt": "2023-07-25T08:19:01.316Z",
            "title": "Eigenverbrauchsoptimierung ",
            "quote": {
                "type": "quote",
                "id": "4h7AoOBjtmo4zpUm8jQMtT",
                "updatedAt": "2023-07-25T07:45:48.362Z",
                "quote": "Der Strom, den eine Photovoltaikanlage aus Sonnenenergie herstellt, kann entweder direkt im Haushalt verbraucht, in Batterien gespeichert oder ins öffentliche Netz eingespeist werden. Der Eigenverbrauch ist der Anteil am erzeugten Strom, den Sie selbst nutzen, also nicht ins Netz einspeisen.",
                "author": "Lina Potreck"
            },
            "description": "<p>Eine gezielte Eigenverbrauchsoptimierung hilft, möglichst viel der von der Photovoltaikanlage erzeugten Energie selbst zu nutzen. Eine besonders effektive Lösung bietet die Kombination aus Wärmepumpen und Photovoltaikanlagen. Durch die intelligente Steuerung und Nutzung des erzeugten Stroms können Sie Ihren Eigenverbrauch maximieren und gleichzeitig den Bezug von teurem Netzstrom reduzieren. Erfahren Sie in unserem Wissens-Artikel mehr darüber.</p>\n",
            "date": "Juli 2023",
            "image": {
                "description": "Referenzbild Eigenverbrauchsoptimierung",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/4RQ77CeRo7qI9jYLZqtxkt/8073165fcdd70ae738125e6e51a01700/2307_-_Referenbild_-_Eigenverbrauchsoptimierung.jpg"
            },
            "link": {
                "type": "link",
                "id": "RekCTNLNdOcVz3atxwIEH",
                "updatedAt": "2023-07-25T07:46:46.846Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/eigenverbrauchsoptimierung"
                }
            }
        },
        {
            "type": "reference",
            "id": "7kzRLDbiERAy3uuf2cHs79",
            "updatedAt": "2023-07-27T14:52:39.292Z",
            "title": "Wärmepumpen Hersteller im Vergleich",
            "quote": {
                "type": "quote",
                "id": "5sqN19ZL4avYmTi8aGnWki",
                "updatedAt": "2021-06-09T18:25:53.657Z",
                "quote": "Mittlerweile gibt es zahlreich etablierte Wärmepumpen Hersteller im Markt. Für viele Hausbesitzer kann diese grosse Vielfalt bei der Auswahl der richtigen Heizung schnell für Überforderung sorgen.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Um den Hausbesitzern die Recherche zu erleichtern, haben wir namhafte und qualifizierte Hersteller, sowie deren Produkte und Leistungen unter die Lupe genommen und stellen diese Hersteller im Vergleich vor.</p>\n",
            "date": "Juli 2023",
            "image": {
                "description": "Foto Vergleich",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/q1Gu5xUsRgUGZq6PaBFo6/c7f7b1e19919a1ec08eeaca63795be30/01---Format---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "38mOt5uuemLxuYMJMoEpiF",
                "updatedAt": "2023-03-09T08:47:34.173Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpen-hersteller-im-vergleich"
                }
            }
        },
        {
            "type": "reference",
            "id": "38MGQEJzHAWWOQO1XmMFSL",
            "updatedAt": "2023-07-03T08:58:18.698Z",
            "title": "Was ist eine Luft/Wasser-Wärmepumpe?",
            "quote": {
                "type": "quote",
                "id": "7jl15pKUCtPacY6s6dLBgb",
                "updatedAt": "2023-07-03T08:54:49.277Z",
                "quote": "Luft/Wasser-Wärmepumpen liegen im Trend. Dies liegt einerseits daran, dass eine Heizleistung aus erneuerbaren Energien erzeugt wird, andererseits ist ihre Leistung höchst effizient und ausfallsicher.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Sie kommen in verschiedenen Variationen vor und ziehen, wie der Name schon andeutet, Energie aus der Luft. So ist es möglich klimafreundlich zu heizen und einen kleinen Beitrag zum Klimaschutz zu leisten.</p>\n",
            "date": "Juni 2023",
            "image": {
                "description": "Referenzbild, welches eine Luft/Wasser-Wärmepumpe aufzeigt",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7ncfW40664q268RFsPrA27/14a1b98fa0b48832a5fe9566a858cf5f/2306_-_Was_ist_eine_Luft-Wasser_WP_Referenzbild.jpg"
            },
            "link": {
                "type": "link",
                "id": "2qIQlJmu8rHIFbDJugywft",
                "updatedAt": "2023-03-09T08:46:53.203Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/was-ist-eine-luft-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "5Oid0VRhPJ1JSnjjtOJCfK",
            "updatedAt": "2023-05-31T13:27:37.903Z",
            "title": "Autarkes Haus mit Photovoltaik und Wärmepumpe",
            "quote": {
                "type": "quote",
                "id": "2KocKlSKvkCJVKCjhZF5X9",
                "updatedAt": "2023-05-31T12:40:19.093Z",
                "quote": "Rohstoffe werden immer knapper und inzwischen spüren wir die negativen Auswirkungen des Klimawandels. Daher ist es unabdingbar, dass wir uns als Gesellschaft auf saubere, erneuerbare Energiequellen wie Photovoltaik und umweltfreundliche Heizmethoden besinnen, um auch den folgenden Generationen eine nachhaltige und lebenswerte Zukunft zu sichern.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>In diesem Zusammenhang wird ein autarkes Haus immer interessanter. Ein solches Haus, das unabhängig von fossilen Brennstoffen wie Öl und Gas arbeitet, ist heute keine Zukunftsmusik mehr. In diesem Artikel erklären wir, was ein autarkes Haus ist, wie eine Wärmepumpe mit Photovoltaik-Strom betrieben werden kann und ob sich eine solche Kombination lohnt.</p>\n",
            "date": "Mai 2023",
            "image": {
                "description": "Titelbild Wissens-Artikel \"autarkes Haus\"",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2mIZNYR3Mee7zN8Ly5pHs8/53fa73414b1e32a465bbb49d0e01d1a3/2305-Referenz-zur-Story--Eine-Fachkraft_-welche-eine-PV-Anlage-installiert.png"
            },
            "link": {
                "type": "link",
                "id": "3YJTrTidO4wJVYeobDYl7x",
                "updatedAt": "2023-05-31T13:30:28.726Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/autarkes-haus-mit-photovoltaik-und-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "2YggowVmRib3Qdy0mflvm0",
            "updatedAt": "2023-05-03T09:22:50.307Z",
            "title": "Wie funktioniert eine Wärmepumpe?",
            "quote": {
                "type": "quote",
                "id": "6dEGwgcefdngmXyUP69yJO",
                "updatedAt": "2021-02-04T20:03:05.412Z",
                "quote": "Eine Wärmepumpe macht sich – ähnlich wie der Kühlschrank – die speziellen Eigenschaften von Kältemitteln zunutze.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Wussten Sie, dass eine Wärmepumpe und ein Kühlschrank nach dem gleichen Prinzip funktionieren? Spätestens dann, wenn Sie Ihre alte Öl- oder Gasheizung in den eigenen vier Wänden durch ein neueres und effizienteres Heizsystem ersetzen möchten, wird Sie diese Frage interessieren.</p>\n",
            "date": "Mai 2023",
            "image": {
                "description": "Grafik zur Erklärung der Funktionsweise einer Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/6DbT7ZXZduEwUuI24qISaY/28d1b37aa90e98863911491caa01c5ca/Referenzbild---wie-funktioniert-eine-w__rmepumpe.png"
            },
            "link": {
                "type": "link",
                "id": "6yPbSbdcZlhVKZ1l9ZMC3G",
                "updatedAt": "2023-03-09T08:46:46.590Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/wie-funktioniert-eine-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "z5foYs0bedT3658vEQf8P",
            "updatedAt": "2023-04-06T08:56:56.138Z",
            "title": "Energieverbrauch senken: Tipps & Tricks",
            "quote": {
                "type": "quote",
                "id": "1o5xgadhMSkgiY0Gi2G6nf",
                "updatedAt": "2023-04-06T08:57:24.623Z",
                "quote": "Um den Energieverbrauch zu senken, ist es wichtig zu verstehen, welche Bereiche den grössten Anteil am Energieverbrauch haben. In einem Einfamilienhaus macht der Stromverbrauch in der Regel rund 30 % aus, der Warmwasser-Energieverbrauch rund 15 % und der Energieverbrauch für die Raumwärme rund 55 %.",
                "author": "Lina Potreck"
            },
            "description": "<p>Der Energieverbrauch beeinflusst den ökologischen Fussabdruck, den wir als Gesellschaft hinterlassen, denn ein hoher Verbrauch führt zu einer höheren CO2-Belastung und damit zu einer stärkeren Umweltverschmutzung. Wir haben für Sie einige Tipps und Tricks gesammelt, um Ihren Energieverbrauch zu senken und Geld zu sparen.</p>\n",
            "date": "April 2023",
            "image": {
                "description": "Referenzbild zum Wissens-Artikel: Energieverbrauch senken",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2Q5eNdEN3WwZ8WeETZkrNJ/95f735431fe8ca1c15d44bb7092b06b5/2304---Energieverbrauch-optimieren---Referenzbild.png"
            },
            "link": {
                "type": "link",
                "id": "6Y0TfZgFYPPJqJzTycID1z",
                "updatedAt": "2023-04-06T08:26:38.568Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/energieverbrauch-senken-praktische-tipps-und-tricks"
                }
            }
        },
        {
            "type": "reference",
            "id": "20QdYRASHpIKPpIwJTvjzw",
            "updatedAt": "2023-03-09T08:46:14.679Z",
            "title": "Heizkurve richtig einstellen",
            "quote": {
                "type": "quote",
                "id": "7DSJslJxCphCwRlUMe5AAn",
                "updatedAt": "2023-03-09T07:54:11.970Z",
                "quote": "Die Heizkurve, auch Heizkennlinie genannt, beschreibt die Beziehung zwischen der Aussentemperatur und der Vorlauftemperatur des Heizkreises (Fussbodenheizung oder Heizkörper), um die gewünschte Innentemperatur Ihres Hauses zu erreichen",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Sie möchten Geld bei Ihren Heizkosten sparen, wissen aber nicht, wo Sie anfangen sollen? Eine der effektivsten Möglichkeiten ist, Ihre Heizkurve richtig einzustellen. In diesem Artikel erfahren Sie, was eine Heizkurve ist, warum sie wichtig ist und wie Sie sie einstellen.</p>\n",
            "date": "März 2023",
            "image": {
                "description": "Titelbild Wissens-Artikel Heizkurve",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/uW3iQB4y4gAK3wGLJTVYH/fcedcc15ecef6857fc355360a4426e02/2303_-_Heizkurve_Titelbild.svg"
            },
            "link": {
                "type": "link",
                "id": "2cs1WYOxxNi5m938jelK6M",
                "updatedAt": "2023-03-09T08:45:48.239Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/heizkurve-richtig-einstellen"
                }
            }
        },
        {
            "type": "reference",
            "id": "4r4O6LJksW3gMt6Q2EhGXm",
            "updatedAt": "2023-02-09T13:15:37.181Z",
            "title": "Die optimale Vorlauftemperatur: Wärmepumpe richtig einstellen",
            "quote": {
                "type": "quote",
                "id": "4UxDtvLMuxlHuEVYeCOlW6",
                "updatedAt": "2023-02-09T12:20:42.865Z",
                "quote": "Die Vorlauftemperatur ist die Temperatur des Heizwassers, welches dem Heizkörper (Radiator) oder der Fussbodenheizung zugeführt wird, damit im Raum die gewünschte Raumtemperatur erreicht wird – ganz unabhängig von den Aussentemperaturen. ",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Damit eine Heizung effizient und sinnvoll arbeiten kann, muss die Vorlauftemperatur richtig eingestellt sein. Das gilt aber nicht nur für Heizkörper, sondern auch für Fussbodenheizungen und Wärmepumpen. In diesem Artikel erfahren Sie, was die Vorlauftemperatur ist und wie Sie sie für eine Wärmepumpe korrekt einstellen.</p>\n",
            "date": "Februar 2023",
            "image": {
                "description": "Titelbild Wissens-Artikel Vorlauftemperatur",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7tPydb3QtvEv6Vbsg75fN2/542697562fff1f9f46465fcf6f256282/2302_-_Referenzbild.svg"
            },
            "link": {
                "type": "link",
                "id": "3h0BcspoMG8BJT32Fw5qJa",
                "updatedAt": "2023-03-09T08:49:04.524Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/die-optimale-vorlauftemperatur-waermepumpe-richtig-einstellen"
                }
            }
        },
        {
            "type": "reference",
            "id": "2kzzU2MIilWQfGahhEdvKe",
            "updatedAt": "2023-01-12T09:24:57.317Z",
            "title": "Fussbodenheizung: Einstellung, Aufbau, Kombination mit Wärmepumpe",
            "quote": {
                "type": "quote",
                "id": "cx4XHjowPfCjnGeUAtHAd",
                "updatedAt": "2023-01-11T08:35:18.207Z",
                "quote": "Es gibt viele Möglichkeiten, sein Zuhause zu heizen. Eine der effektivsten und kostengünstigsten Möglichkeiten ist die Kombination aus Wärmepumpe und Fussbodenheizung.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Die Wärmepumpe ist eine effiziente Art, um die Wärme in Ihrem Zuhause zu regulieren. Wie effizient sie tatsächlich arbeiten kann, hängt allerdings von einigen Faktoren ab. So kann sich zum Beispiel Ihre Fussbodenheizung auf die Arbeit der Wärmepumpe auswirken – je nachdem, wie gut sie funktioniert. In diesem Artikel erfahren Sie das Wichtigste über die Kombination aus Fussbodenheizung und Wärmepumpe.</p>\n",
            "date": "Januar 2023",
            "image": {
                "description": "Ein Foto von einer Fussbodenheizung",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2kbttHeLYhMTpTtsrQLaR7/9e42a6eb0c326ee70ceb938e0f307c6f/2301---Fussbodenheizung---Bild-Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "XGygIyiVhviUOK8tRGynh",
                "updatedAt": "2023-03-09T08:48:58.202Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/fussbodenheizung-einstellung-aufbau-kombination-mit-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "1ZOJQb3b22yBnpAHlbYoWX",
            "updatedAt": "2022-12-21T07:24:34.792Z",
            "title": "Ist eine Wärmepumpen steuerlich absetzbar?",
            "quote": {
                "type": "quote",
                "id": "5GgGcK0IqVoEoKe2FfScNC",
                "updatedAt": "2022-12-13T20:38:48.488Z",
                "quote": "Die meisten Kantone haben die im Jahr 2020 auf Bundesebene eingeführten Steuerabzüge für energetische Sanierungen übernommen. Nicht nur die Kosten für den Ersatzbau, sondern auch die Rückbaukosten der alten Heizung sind steuerlich abzugsfähig.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Ersetzen Sie Ihre Heizung durch eine Wärmepumpe, gelten die Kosten für die Wärmepumpe steuerlich als Liegenschaftsunterhalt und können daher von der Steuer abgesetzt werden. Wie öffentliche Fördergelder zu berücksichtigen sind, inwiefern die Rückbaukosten abgesetzt werden können und ob eine Verteilung auf mehrere Steuerperioden zulässig ist, erfahren Sie in diesem Artikel.</p>\n",
            "date": "Dezember 2022",
            "image": {
                "description": "Ein Sparschwein auf einem Dokument, welches das steuerliche Absetzen einer Wärmepumpe darstellen soll",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5PCVjPkpKVATCRbfBX74IG/52241f44ca28314b2f5078b8c98a40a4/2212---WP-steuerlich-absetzen-Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "2B3PCUMimZaFYa1KvG1hTN",
                "updatedAt": "2023-03-09T08:48:53.790Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/ist-eine-waermepumpen-steuerlich-absetzbar"
                }
            }
        },
        {
            "type": "reference",
            "id": "5ifsev5DRfTnaDAU08FXGp",
            "updatedAt": "2022-11-15T14:03:04.706Z",
            "title": "Lebensdauer einer Wärmepumpe",
            "quote": {
                "type": "quote",
                "id": "18MPttLb86zRYTR5Ria3o9",
                "updatedAt": "2022-11-15T14:01:41.965Z",
                "quote": "Auch wenn eine Wärmepumpe anscheinend problemlos funktioniert, kann sie bereits in die Jahre gekommen sein und an Effizienz verloren haben. Das merken Sie vor allem, wenn Ihr Zuhause nicht mehr warm wird oder Ihre jährlichen Energiekosten steigen.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Die Art der Wärmepumpe, die Qualität der Anlage – diese und viele weitere Faktoren beeinflussen die Lebensdauer einer Wärmepumpe. Auf was Sie vor dem Kauf achten sollten und wie Sie die Lebensdauer Ihrer Wärmepumpe positiv beeinflussen können, haben wir in diesem Bericht für Sie zusammengefasst.</p>\n",
            "date": "November 2022",
            "image": {
                "description": "Eine Sanduhr, welche die Lebensdauer einer Wärmepumpe visualisiert.",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/B0huoa2Wok65fiEUg8uyE/db84a25a0e6f8000b09c1ac103004067/2211---Lebensdauer-WP---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "7M3skNDe9h4CUwi6IXgGe",
                "updatedAt": "2023-03-09T08:48:48.273Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/lebensdauer-einer-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "68tTovils57TDaCQGq4XPu",
            "updatedAt": "2022-10-18T11:40:13.999Z",
            "title": "Der optimale Aufstellort für Ihre Wärmepumpe",
            "quote": {
                "type": "quote",
                "id": "22BI1SIrLEHfhBQaE1vaYD",
                "updatedAt": "2022-10-18T11:40:09.250Z",
                "quote": "Der Entschluss ist gefasst: Eine umweltfreundliche Wärmepumpe soll die alte Heizung ersetzen. Aber wo soll das gute Stück stehen und welche rechtlichen Vorgaben müssen dabei beachtet werden? Hier finden Sie die wichtigsten Infos rund um den Aufstellort Ihrer neuen Wärmepumpe.",
                "author": "Lina Potreck"
            },
            "description": "<p>Eine Luft/Wasser-Wärmepumpe muss die Lärmschutzverordnung erfüllen, damit auf dem eigenen Grundstück und den umliegenden niemand durch zu laute Geräusche gestört wird. Dies gilt sowohl für innen- wie auch aussenaufgestellte Wärmepumpen.</p>\n",
            "date": "Oktober 2022",
            "image": {
                "description": "Referenzbild Aufstellort Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3aMzrKWPTtCwv75Wzmoq4d/ce94ba63788c40c2b16b2b3dc7e8b7bb/2211---Lebensdauer-WP---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "1okZfYxpWjZOENpURy2cIH",
                "updatedAt": "2023-03-09T08:48:43.274Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/der-optimale-aufstellort-fuer-ihre-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "7xnYbdlt5Kjm8itbRSrJDk",
            "updatedAt": "2022-09-22T20:53:36.042Z",
            "title": "Wärmepumpen-Vergleich: Was können die verschiedenen Typen?",
            "quote": {
                "type": "quote",
                "id": "4sfQ8YOYfMHpwK15aCm84w",
                "updatedAt": "2022-09-22T20:52:01.054Z",
                "quote": "Jede Wärmepumpe hat Ihre Vor- und Nachteile. Schauen Sie vorrangig auf die Initialkosten, könnte eine Luft/Wasser-Wärmepumpe die richtige Wahl sein. Suchen Sie nach einer effizienten Wärmepumpe mit wenig Platzbedarf, lohnt sich eine Sole/Wasser-Wärmepumpe. ",
                "author": "Markus Hofmann"
            },
            "description": "<p>Luft, Sole, Wasser, innen, aussen – es gibt verschiedenste Arten von Wärmepumpen. Im Vergleich haben alle ihre Vor- und Nachteile. Doch welche Art der Wärmepumpe punktet womit? Das haben wir uns genauer angesehen und für Sie aufbereitet – in ausführlicher Text- und übersichtlicher Tabellenform.</p>\n",
            "date": "September 2022",
            "image": {
                "description": "Referenzbild Wissens-Artikel Wärmepumpen-Vergleich",
                "contentType": "image/svg+xml",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/9MJ6EXmwED2tHmDmZANTM/e85910ae261e00f2c1ec2db05da9dd47/2209_-_Grafik_zur_Story.svg"
            },
            "link": {
                "type": "link",
                "id": "1aXKDK8CyH9xRsKL1TZQeW",
                "updatedAt": "2023-03-09T08:48:37.523Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpen-vergleich-was-koennen-die-verschiedenen-typen"
                }
            }
        },
        {
            "type": "reference",
            "id": "26hianfuUqVG9kJzHy5I8N",
            "updatedAt": "2022-08-24T11:41:11.501Z",
            "title": "Gasheizung ersetzen: Kosten, Förderungen und Regelungen in der Schweiz",
            "quote": {
                "type": "quote",
                "id": "6GsAoXu0HXOMAXN48RWZX9",
                "updatedAt": "2022-08-24T11:41:09.159Z",
                "quote": "Die alte Gasheizung ist schon über 10 Jahre alt – und was jetzt? Durch eine andere Heizung ersetzen? Das kann sich für Ihr Portemonnaie und die Umwelt lohnen! Wir haben uns angesehen, welche Möglichkeiten Sie beim Heizungswechsel haben, welche Kosten auf Sie zukommen und ob es Fördermöglichkeiten gibt.",
                "author": "Heizungsmacher AG"
            },
            "description": "<p>Wenn Sie Ihre Gasheizung ersetzen und zukünftig nicht mehr auf Gas setzen möchten, haben Sie einige Möglichkeiten zur Auswahl. Zu den nachhaltigen Komplettlösungen gehören die Wärmepumpe, die Pelletheizung und die Fernwärmeheizung.</p>\n",
            "date": "August 2022",
            "image": {
                "description": "Bild einer Industrie mit grossen Kaminen welche Co2 ausstossen vor blau-violettem Hintergrund/Himmel",
                "contentType": "image/jpeg",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3tUsSwOnINjk0UURRJYXlu/d515abca1979665a5017be2162e2df36/136158668_m-1024x683.jpg"
            },
            "link": {
                "type": "link",
                "id": "5qt8Kpuo98cy4mW6L3xg6F",
                "updatedAt": "2023-03-09T08:48:32.621Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/gasheizung-ersetzen-kosten-foerderungen-und-regelungen-in-der-schweiz"
                }
            }
        },
        {
            "type": "reference",
            "id": "2tr0LzHBEjhWBl8Z0domLV",
            "updatedAt": "2022-07-27T08:28:50.146Z",
            "title": "Wärmepumpen im Altbau: Lohnt sich das?",
            "quote": {
                "type": "quote",
                "id": "2ZHJBKwgRH8rachUwfMeGT",
                "updatedAt": "2022-08-24T10:47:20.173Z",
                "quote": "Dass das Nachrüsten in einem Bestandsgebäude komplizierter ist, als die Wärmepumpe gleich beim Hausbau einzuplanen, ist logisch. Trotzdem ist es ökologisch und wirtschaftlich sinnvoll, auch in einem Altbau auf Umweltwärme zu setzen, wenn bestimmte bauliche Voraussetzungen erfüllt sind.",
                "author": "Heizungsmacher AG"
            },
            "description": "<p>Um die Frage für Sie direkt zu beantworten: Das Installieren einer Wärmepumpe als Ersatz für fossile Heizsysteme ist nicht nur bei Neubauten, sondern auch im Altbau grundsätzlich möglich – und in den meisten Fällen auch finanziell lohnenswert. Welche Voraussetzungen dafür erfüllt sein müssen, erfahren Sie direkt in unserem Wissens-Artikel.</p>\n",
            "date": "Juli 2022",
            "image": {
                "description": "Ein Foto von einem Altbau Haus",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/53LpaBCRbTx1mIFNnRzVYG/492187da6273d8a81f09b08ad5091f22/2207---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "7Jk3ctEmiSoPAgNEiEbUPW",
                "updatedAt": "2023-03-09T08:48:27.910Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpen-im-altbau-lohnt-sich-das"
                }
            }
        },
        {
            "type": "reference",
            "id": "46zHltOgHETVZQHAE7Rv1i",
            "updatedAt": "2022-06-29T08:36:15.653Z",
            "title": "Stromverbrauch einer Wärmepumpe",
            "quote": {
                "type": "quote",
                "id": "6D4mkWWYCtFm3emZJ0T0rn",
                "updatedAt": "2022-06-29T07:58:10.406Z",
                "quote": "Welche Wärmepumpen-Arten besonders effizient sind und was Sie ansonsten über den Stromverbrauch noch wissen sollten, erfahren Sie in diesem Wissensartikel.",
                "author": "Markus Hofmann WeVenture"
            },
            "description": "<p>Wärmepumpen entziehen der Umwelt – also ihrer Umgebungsluft, dem Erdreich sowie dem Grundwasser – Wärme, die man mittels Kältemittelkreislauf nutzbar machen kann. Für genau diese Antriebsenergie braucht eine Wärmepumpe am Ende Strom. Die gute Nachricht für Sie als Endnutzer: Der Stromanteil an der Wärmeerzeugung liegt bei gerade einmal 25 Prozent. Mit anderen Worten heizen Sie Ihre Wohnung bis zu 75 Prozent mit kostenloser Umweltenergie.</p>\n",
            "date": "Juni 2022",
            "image": {
                "description": "Titelfoto Stromverbrauch Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5QGGJtM3SYGcPa7nrpu0Tw/103370aabcccfd40bca95e358aabd844/2206---Artikel__bersicht-Foto.png"
            },
            "link": {
                "type": "link",
                "id": "2LqO79MOmqfmTWkiQUj6LF",
                "updatedAt": "2023-03-09T08:48:21.569Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/stromverbrauch-einer-waermepumpe"
                }
            }
        },
        {
            "type": "reference",
            "id": "3yDIzV4mRPaMZQzKYD2AGR",
            "updatedAt": "2022-06-01T06:11:27.110Z",
            "title": "Voraussetzungen für eine Wärmepumpe",
            "quote": {
                "type": "quote",
                "id": "7bH3LuwNUsmjZ8OIrFyttk",
                "updatedAt": "2022-06-01T06:08:38.589Z",
                "quote": "Welche Wärmepumpe zu welcher Gebäudeart besonders gut passt und welche allgemeinen baulichen Voraussetzungen dafür gegeben sein müssen, erfahren Sie in diesem Wissens-Artikel. \n",
                "author": "Markus Hofmann"
            },
            "description": "<p>In den beiden letzten Jahren wuchs der Wärmepumpenmarkt um jeweils rund 20% – Tendenz steigend. Alleine im Jahr 2021 wurden rund 33’700 Wärmepumpen verkauft. Wirklich überraschen sollte dies nicht, schliesslich sind Wärmepumpen im Betrieb die günstigste und umweltfreundlichste Heizung auf dem Markt.</p>\n",
            "date": "Juni 2022",
            "image": {
                "description": "Referenzbild Voraussetzungen Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/VG6NMyObbSeuH4awsOhNU/e077dd6898f0eac720a3d58ff67d22e9/2205---Referenzbild.png"
            },
            "link": {
                "type": "link",
                "id": "2Xho61f4R60cb1Gj2eZWPP",
                "updatedAt": "2023-03-09T08:48:15.904Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/voraussetzungen-fuer-eine-waermepumpe-ist-mein-haus-geeignet"
                }
            }
        },
        {
            "type": "reference",
            "id": "3MR28DfIAkP2BtVLJpkEmp",
            "updatedAt": "2022-05-05T08:52:03.277Z",
            "title": "Kühlen mit Wärmepumpe - so gehts!",
            "quote": {
                "type": "quote",
                "id": "5lvJhX08kK1ThdmlXa3R3P",
                "updatedAt": "2022-05-05T06:36:03.278Z",
                "quote": "Angesichts der Temperaturen in der Schweiz, sollten Sie sich gut überlegen, ob die Investition in eine Klimaanlage sinnvoll ist oder ob Sie gleich eine Wärmepumpe zum Kühlen verwenden.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Wer kennt es nicht: Es ist Sommer, die Sonne brennt und das Haus wird immer heisser. Die meisten würden jetzt an die Anschaffung einer Klimaanlage denken, oder? Wussten Sie, dass Sie auch mit einer Wärmepumpe die Innenraum-Temperaturen senken können? Wie das geht, erfahren Sie hier.</p>\n",
            "date": "Mai 2022",
            "image": {
                "description": "Referenzbild: Kühlen mit Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/7klkNsKm9nMnVKnPGsLKDa/e56d9651d575ef8e11ed3c939c35dcaf/1---Bild-Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "6khIuggOKcrlhQTQB4D1vE",
                "updatedAt": "2023-03-09T08:48:10.959Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/kuehlen-mit-waermepumpe-so-gehts"
                }
            }
        },
        {
            "type": "reference",
            "id": "4XhKZQzige5GGiy7quXwTw",
            "updatedAt": "2022-03-10T09:08:02.586Z",
            "title": " myclimate: So stellen Sie Ihren Förderungsantrag für einen Heizungsersatz",
            "quote": {
                "type": "quote",
                "id": "2l8pKch5wvJodSfIDEcxQl",
                "updatedAt": "2022-05-05T06:35:45.401Z",
                "quote": "myclimate sieht sich global und lokal als Partner für wirksamen Klimaschutz. Gemeinsam mit Partnern aus der Wirtschaft sowie Privatpersonen will die Organisation die Zukunft der Welt nachhaltig gestalten.",
                "author": "Michelle Bolliger"
            },
            "description": "<p>Mit Projekten höchster Qualität treibt myclimate weltweit messbaren Klimaschutz und eine nachhaltige Entwicklung voran. Die freiwillige Kompensation von CO₂-Emissionen erfolgt in derzeit mehr als 144 Klimaschutzprojekten in 41 Ländern.</p>\n",
            "date": "März 2022",
            "image": {
                "description": "myclimate Logo Referenzbild",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/44DgEQwB6hhBOGqRhbH1Yn/8abe9d3ba3e440286362593f13ebadc9/2203---myclimate-Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "1vEfBVIsc1vkVMz3UI8XNo",
                "updatedAt": "2023-03-09T08:47:28.987Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/myclimate-so-stellen-sie-ihren-foerderungsantrag-fuer-einen-heizungsersatz"
                }
            }
        },
        {
            "type": "reference",
            "id": "ogoKWdcKOqjyFNDuzbejm",
            "updatedAt": "2022-03-10T09:02:02.738Z",
            "title": "Wärmepumpe installieren mit Heizungsmacher: Der Weg zum nachhaltigen Heizen",
            "quote": {
                "type": "quote",
                "id": "2pRXS0aABOcLGODtNuLgSi",
                "updatedAt": "2022-02-16T18:33:17.596Z",
                "quote": "Ist Ihre Öl- oder Gasheizung in die Jahre gekommen und Sie möchten nun eine nachhaltige Wärmepumpe installieren? Dann sind Sie bei den Heizungsmachern genau richtig.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Wir erklären Ihnen nachfolgend worauf Sie achten müssen und wie eine Wärmepumpeninstallation mit uns abläuft!</p>\n",
            "date": "Februar 2022",
            "image": {
                "description": "NIBE Wärmepumpe vor weissem Haus am See",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/1E0wyt5JZ8HfHk6TLkR558/df37a87d8dc1b786c679c795f496c348/Bild-W__rmepumpe---Content-Block-Einleitung_Bild.png"
            },
            "link": {
                "type": "link",
                "id": "4jC64hP9Hc2qqSw4m0D0Lj",
                "updatedAt": "2023-03-09T08:48:06.484Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpe-installieren-mit-heizungsmacher-der-weg-zum-nachhaltigen-heizen"
                }
            }
        },
        {
            "type": "reference",
            "id": "4ETXQ9P2xUz74WtbAsC5rh",
            "updatedAt": "2021-11-30T08:34:31.265Z",
            "title": "Alle Infos zum GEAK (Gebäudeenergieausweis der Kantone)",
            "quote": {
                "type": "quote",
                "id": "4Mqn4uNUmyKQkLLFI873jf",
                "updatedAt": "2021-11-24T09:03:15.624Z",
                "quote": "Haben Sie vor Ihre Heizung zu ersetzen oder eine andere energetische Sanierung zu tätigen und brauchen eine Entscheidungshilfe? Dann sind Sie mit dem GEAK bestens ausgerüstet.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Der schweizweit einheitliche Gebäudeenergieausweis der Kantone (GEAK) beurteilt den Zustand Ihrer Gebäudehülle, wie energieeffizient Ihr Gebäude ist und wie viel Energie bei Standardnutzung benötigt wird.</p>\n",
            "date": "November 2021",
            "image": {
                "description": "GEAK Logo",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/3nXhish0iYhSftiyBDSIvI/563ddff12e49a4fa02a299cb85b437cf/2111---GEAK-Logo-Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "5L2Pbw73WAIw1Rw7djaTCJ",
                "updatedAt": "2023-03-09T08:47:59.965Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/alle-infos-zum-geak-gebaeudeenergieausweis-der-kantone"
                }
            }
        },
        {
            "type": "reference",
            "id": "6M7AVU6QijMrhbyQPWu6kO",
            "updatedAt": "2021-11-24T09:36:44.547Z",
            "title": "Wie spare ich Heizkosten?",
            "quote": {
                "type": "quote",
                "id": "2LsUWf3FS4AFuBVNGmHcYv",
                "updatedAt": "2021-10-25T17:33:55.473Z",
                "quote": "Die Energie für die Wärmeerzeugung macht den grössten Anteil der gesamten Energie in einem Haushalt aus. Angesichts dessen mag es kaum verwundern, dass sich hier relativ hohe Energiesparpotenziale realisieren lassen.",
                "author": "Marcel Bachmann"
            },
            "description": "<p>Heizkosten zu sparen ist einfacher als man denkt. Bereits kleine Anpassungen können grosse Auswirkungen haben. Wir haben 10 Tipps zusammengestellt um nachhaltig Heizkosten zu sparen.</p>\n",
            "date": "Oktober 2021",
            "image": {
                "description": "3D Illustration Heizkosten senken Energieeffizeinz",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/2inHX6XLGYGVsSnudnlN33/b8838982011e25e52fbe5160691536c5/2110---Heizkosten-sparen-Referenz-zum-Artikel.png"
            },
            "link": {
                "type": "link",
                "id": "4dVffWlPcoFyUIFK1i8kf1",
                "updatedAt": "2023-03-09T08:47:53.792Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/heizkosten-sparen"
                }
            }
        },
        {
            "type": "reference",
            "id": "773JRZeUHW7ST0zLuWgb3D",
            "updatedAt": "2021-09-09T09:55:03.454Z",
            "title": "Wärmepumpen-Boiler",
            "quote": {
                "type": "quote",
                "id": "1laH7wlx9dTpo18D4jOtj4",
                "updatedAt": "2021-09-09T08:22:14.937Z",
                "quote": "Ein Wärmepumpen-Boiler - auch bekannt als Brauchwasser-Wärmepumpe - dient zur ökonomischen und effizienten Warmwasserversorgung von Haushalten sowie Gewerben.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Der Wärmepumpen-Boiler wird für Sie interessant, wenn Sie Ihren alten Elektro-Boiler ersetzen wollen. In der Praxis wird der Austausch eines Elektro-Boilers oft mit einem Heizungsersatz kombiniert. In diesem Artikel erfahren Sie, was ein Wärmepumpen-Boiler ist und wo er eingesetzt wird.</p>\n",
            "date": "September 2021",
            "image": {
                "description": "Referenz zur Story - Bild WP Boiler",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/36rMXPFz5VtmdHfKQgPwZ6/ddeeb08dbbc6298f9cecf75d1b249098/01---Format---Referenz-zur-Story.png"
            },
            "link": {
                "type": "link",
                "id": "3WL4tJ3RDmLjuoeAWqKB8U",
                "updatedAt": "2023-03-09T08:47:47.926Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpen-boiler"
                }
            }
        },
        {
            "type": "reference",
            "id": "zNhCxoJBIT3WIF7SRBHCA",
            "updatedAt": "2021-03-18T13:00:02.323Z",
            "title": "Wärmepumpe und Photovoltaik - ein wirtschaftliches Duo",
            "quote": {
                "type": "quote",
                "id": "1LGEzkyt5uSfV8t3p7SWRQ",
                "updatedAt": "2021-03-18T09:49:59.601Z",
                "quote": "Alleine eine Wärmepumpe nutzt bis zu 80% frei verfügbare Umweltwärme als Energiequelle, beispielsweise aus der Luft, dem Erdreich oder dem Grundwasser. Kommt zusätzlich zur Wärmepumpe noch eine Photovoltaikanlage ins Spiel, dann werden an sonnigen Tagen die restlichen 20% und darüber hinaus mit der Sonnenenergie abgedeckt.",
                "author": "Sinem Alagöz"
            },
            "description": "<p>Denken Sie über einen nachhaltigen Heizungsersatz nach? Dann macht es durchaus Sinn, sich Gedanken über die Synergie von Wärmepumpe und Photovoltaikanlage zu machen. Diese Kombinations-Lösung bringt Unabhängigkeit mit sich und lässt damit die Betriebs- und Heizkosten maximal optimieren.</p>\n",
            "date": "März 2021",
            "image": {
                "description": "Referenzfoto Photovoltaik und Wärmepumpe",
                "contentType": "image/png",
                "url": "//images.ctfassets.net/wbm1kpreb3m8/5hFAbTgkJxsylaQRzSJ4jy/a0785a2ec3d664bf131521be269aa8cc/01---Format---Referenz-zum-Story.png"
            },
            "link": {
                "type": "link",
                "id": "Y6yqSfhgabiWWNFIwiCkm",
                "updatedAt": "2023-03-09T08:47:12.358Z",
                "label": "Ganzen Artikel lesen",
                "page": {
                    "slug": "wissen/waermepumpe-und-photovoltaik-ein-wirtschaftliches-duo"
                }
            }
        }
    ],
    "anchorName": "uebersicht-blogartikel"
}} location={props.location}
          />
        </div>
    </Layout>
  )
}

export default Page
